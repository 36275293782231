import { postData, getData, patchData, getFile, deleteData } from "./rest";
import { Programme, User } from "common/build/prisma/client";
import { ProgrammeType } from "../../models/modelTypes";
import { objectToQueryString } from "../../utils/api";

// Initially, I couldn't use Programme from prisma as an Id is required
// We can still use it using Partial<Type>.
// Partial makes all properties of a type optional
// Similarly, Required<Type> makes all properties of a type required
export const addProgramme = async (programmeDetails: Partial<Programme>): Promise<Response> =>
    await postData("/programme/add", { ...programmeDetails });

export const addClassroomProgramme = async (programmeDetails: Partial<Programme>): Promise<Response> =>
    await postData("/programme/add/classroom", { ...programmeDetails });

export const addProgrammeStoryCollection = async (
    storyCollectionId: number,
    programmeId: number | undefined,
): Promise<Response> =>
    await patchData(`/programme/${programmeId}/add-collection`, { storyCollectionId: storyCollectionId });

export const list = async (args: Record<string, unknown>): Promise<{ items: ProgrammeType[]; total: number }> =>
    await getData(`/programme${objectToQueryString(args)}`);

export const get = async (id: number, args: Record<string, unknown>): Promise<ProgrammeType> =>
    await getData(`/programme/${id}${objectToQueryString(args)}`);

export const connectFacilitatorUserToProgramme = async (
    programmeId: number,
    facilitatorUserDetails: Partial<User>,
    create: boolean,
): Promise<Response> => {
    if (create) {
        return await postData(`/programme/${programmeId}/facilitator/add`, facilitatorUserDetails);
    } else {
        return await postData(`/programme/${programmeId}/facilitator`, facilitatorUserDetails);
    }
};

export const removeFacilitatorUserFromProgramme = async (
    programmeId: number,
    facilitatorId: number,
): Promise<Response> => await patchData(`/programme/${programmeId}/facilitator/${facilitatorId}`);

export const update = async (id: number, programmeDetails: Partial<Programme>): Promise<Response> =>
    await patchData(`/programme/${id}`, { ...programmeDetails });

export const generateSessions = async (programmeId: number | undefined, startIndex?: number): Promise<Response> =>
    await patchData(`/programme/${programmeId}/create-sessions`, { startIndex: startIndex });

export const addParticipant = async (
    programmeId: number,
    participantDetails: {
        id: number;
        name?: string;
        email?: string | null;
        phoneNumber?: string | null;
        organisationId?: number;
    },
): Promise<Response> => await patchData(`/programme/${programmeId}/add-participant`, { ...participantDetails });

export const getQRCodesPDF = async (programmeId: number): Promise<void> =>
    await getFile(`/programme/${programmeId}/generate-pdf`);

export const optOutAdmitParticipants = async (programmeId: number): Promise<Response> =>
    await patchData(`/programme/${programmeId}/opt-out-admit`);

export const deleteProgramme = async (id: number): Promise<Response> => deleteData(`/programme/${id}`);
