import {
    Box,
    makeStyles,
    Typography,
    useMediaQuery,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tabs,
    Tab,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAlert } from "../../../contexts/alert.context";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { useMobile } from "../../../hooks/useMobile";
import { ProgrammeType, StoryCollectionType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { Error } from "../../../core/components/Error";
import { DestructiveButton, PrimaryButton, SecondaryButton } from "../../layout/Button/Button";
import { Delete, RestoreFromTrash } from "@material-ui/icons";
import { NextSessionCard } from "../../session/components/NextSessionCard";
import { AllSessionsGrid } from "../../session/components/AllSessionsGrid";
import Spinner from "../../layout/Spinner";
import { deleteProgramme, generateSessions, update } from "../../../client/api/programme";
import { TabPanel } from "../../programme/screens/ViewProgramme";
import { useQueryStringState } from "../../../hooks/useQueryString";
import { TeacherNotes } from "../teachers/TeacherNotes";
import { useSetTourState } from "../../tour/Store";
import { GameActivities } from "../components/GameActivities";
import { GameActivities2 } from "../components/GameActivities2";
import { GameActivities3 } from "../components/GameActivities3";

const tabs = ["sessions", "teacher-guide"];

export const ViewClassProgramme = (): JSX.Element => {
    const history = useHistory();
    const { isMobile, isTablet } = useMobile();
    const { item, fetching, fetchItem } = useFetch<ProgrammeType>();
    const { setAlert } = useAlert();
    const setTourState = useSetTourState();

    const [gameActivities, setGameActivities] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [tabValue, setTabValue] = useQueryStringState("tab", tabs[0]);
    const tabIndex = Math.max(tabs.indexOf(tabValue), 0);

    // delete dialog
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const fullScreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    // New Session Dialog
    const [newSessions, setNewSessions] = useState(false);

    const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setTabValue(tabs[newValue]);
    };

    const { id, programmeId } = useParams<{ id: string; programmeId: string }>();

    const refGuideTab = useRef<HTMLDivElement | null>(null);
    const refSessionTab = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("programmeGuideTab", refGuideTab) }));
    }, [refGuideTab]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("programmeSessionTab", refSessionTab) }));
    }, [refSessionTab]);

    const useStyles = makeStyles(() =>
        createStyles({
            headerColor: {
                backgroundColor: "#F9F9FB",
            },
            sessionsOverview: {
                display: "block",
                [verbalTheme.breakpoints.up("md")]: {
                    display: "flex",
                },
            },
            sessionsAll: {
                [verbalTheme.breakpoints.up("md")]: {
                    flex: 1,
                },
            },
            sessionsNext: {
                maxWidth: "100%",
                marginRight: verbalTheme.spacing(3),
                marginBottom: verbalTheme.spacing(3),
                [verbalTheme.breakpoints.up("md")]: {
                    maxWidth: "220px",
                    marginBottom: verbalTheme.spacing(0),
                },
            },
        }),
    );

    const classes = useStyles();

    useEffect(() => {
        if (parseInt(programmeId) !== item?.id) {
            fetchItem(parseInt(programmeId), { includeStoryCollection: true })
                .then(() => {
                    setError(false);
                })
                .catch(() => setError(true));
        }

        if (item?.StoryCollection?.name?.trim().toLowerCase() === "managing emotions 1-2-1 (beacon)") {
            tabs.push("activities-day-1", "activities-day-2", "activities-day-3");
            setGameActivities(true);
        }

        // flag to open dialog informing user
        // we generate new sessions if the collection has new stories
        if (item?.StoryCollection?.Stories?.length && item?.Sessions?.length) {
            if (item.StoryCollection.Stories.length > item.Sessions.length) {
                setNewSessions(true);
            }
        }
    }, [programmeId, item?.id, item?.StoryCollection?.name, item?.Sessions, item?.StoryCollection?.Stories]);

    const handleNewSessions = () => {
        generateSessions(item?.id, item?.Sessions?.length).then(() => {
            console.log("generatedSessions");
            setNewSessions(false);
            // Refresh if posible, posibly show a dialog and inform user, tell them new sessions available and have been added.
            fetchItem(parseInt(programmeId), { includeStoryCollection: true })
                .then(() => {
                    setError(false);
                })
                .catch(() => setError(true));
        });
    };

    if (error) {
        return <Error description={`Unable to load Programme`} />;
    }

    return (
        <>
            {!isTablet && isMobile && <div style={{ marginTop: 56 }} />}
            <Box className={classes.headerColor}>
                <Box p={4}>
                    <Box mb={2}>
                        <Typography variant="h2" component="h1" color="textPrimary" align="left" id="prog-label">
                            {`${item && item["name"] ? `${item["name"]}` : `Programme`}`}
                        </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems="center" id="prog-freq">
                        <Typography variant="body2" color="textPrimary" align="left">
                            {`${item ? `${item.frequency} / ${item.Sessions?.length} Sessions` : ""}`}
                        </Typography>

                        <DestructiveButton
                            id="prog-delete"
                            startIcon={item?.deleted ? <RestoreFromTrash /> : <Delete />}
                            size="small"
                            onClick={() => setConfirmDelete(true)}
                        >
                            {item?.deleted ? "Restore" : "Delete"} Programme
                        </DestructiveButton>
                    </Box>
                </Box>
            </Box>

            {!fetching && item ? (
                <>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={classes.headerColor}
                    >
                        <Tab label="Sessions" id="sessions-tab" ref={refSessionTab} />

                        <Tab label="Teacher Guide" id="teachers-guide-tab" ref={refGuideTab} />

                        {gameActivities ? <Tab label="Activities Day 1" id="activities-day-1" /> : null}
                        {gameActivities ? <Tab label="Activities Day 2" id="activities-day-2" /> : null}
                        {gameActivities ? <Tab label="Activities Day 3" id="activities-day-3" /> : null}
                    </Tabs>
                    <TabPanel index={0} value={tabIndex}>
                        <Box mb={3} mx={3} mt={1}>
                            {item && item.Sessions && item.Sessions.length > 0 && (
                                <>
                                    <div className={classes.sessionsOverview}>
                                        {item.Sessions.filter((s) => s.state !== "Complete").length > 0 && (
                                            <div className={classes.sessionsNext} id="next-session">
                                                <NextSessionCard classroomId={parseInt(id)} sessions={item?.Sessions} />
                                            </div>
                                        )}
                                        <div className={classes.sessionsAll} id="all-sessions">
                                            <AllSessionsGrid sessions={item?.Sessions} excludeNextSession={true} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(!item || !item.Sessions || item.Sessions.length === 0) && (
                                <>
                                    <Typography variant="h2">No Sessions Found</Typography>
                                </>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel index={1} value={tabIndex}>
                        <TeacherNotes storyCollection={item?.StoryCollection as StoryCollectionType} />
                    </TabPanel>
                    <TabPanel index={2} value={tabIndex}>
                        <GameActivities />
                    </TabPanel>
                    <TabPanel index={3} value={tabIndex}>
                        <GameActivities2 />
                    </TabPanel>
                    <TabPanel index={4} value={tabIndex}>
                        <GameActivities3 />
                    </TabPanel>
                </>
            ) : (
                <Spinner />
            )}
            <Dialog open={newSessions} onClose={() => handleNewSessions()}>
                <DialogTitle>New Sessions are available for this programme!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The programme has{" "}
                        {(item?.StoryCollection?.Stories?.length || 0) - (item?.Sessions?.length || 0)} new session/s.
                        The extra session/s will now be generated for your programme.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <PrimaryButton onClick={() => handleNewSessions()}>OK</PrimaryButton>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)} fullScreen={fullScreen}>
                <DialogTitle>{item?.deleted ? "Restore" : "Delete"} Programme?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Are you sure you want to ${
                        item?.deleted ? "restore" : "delete"
                    } this programme?`}</DialogContentText>
                    {item?.deleted ? (
                        <DialogContentText>
                            If you restore this programme it will be available in the programme list again. Any report
                            data will also be restored. If you choose to delete this programme again, it will be
                            archived for 30 days then it will be deleted permanently
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            If you delete this programme it willl be archived for 30 days. You can restore the programme
                            at any time before 30 days. After 30 days the programme will be permanently deleted. You can
                            view Archived programmes on the programme list using the Archived filter
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <DestructiveButton
                        startIcon={item?.deleted ? <RestoreFromTrash /> : <Delete />}
                        onClick={() => {
                            if (item?.id) {
                                if (item.deleted) {
                                    update(item.id, { deleted: false }).then(() => {
                                        setAlert("Programme successfully restored", "success");
                                        history.push(`/classrooms/view/${id}`);
                                    });
                                } else {
                                    deleteProgramme(item.id).then(() => {
                                        setAlert(
                                            "Programme successfully deleted. Go to the status drop down menu if you need to revisit any of your archived programmes",
                                            "success",
                                            6000,
                                        );
                                        history.push(`/classrooms/view/${id}`);
                                    });
                                }
                            }
                        }}
                    >
                        {item?.deleted ? "Restore" : "Delete"} Programme
                    </DestructiveButton>
                    <SecondaryButton onClick={() => setConfirmDelete(false)}>Cancel</SecondaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
