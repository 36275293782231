import React, { Context, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { ListParameters } from "../../../../../../common/build/api-parameters/common";
import { useCrud } from "../contexts/crud.context";

export interface ListContextI<T, CT = unknown> {
    list: T[];
    total?: number;
    currentPage: number;
    loading: boolean;
    initialised: boolean;
    error?: string;
    checked: CT[];
    plural: string;

    fetchList: (args?: Record<string, unknown> & ListParameters) => Promise<void>;
    fetchExportList: (args?: Record<string, unknown> & ListParameters) => Promise<T[]>;
    handlePageChange: (page: number) => void;
    handleSearchTermChange: (term: string) => void;
    handleFilterChange: (filter: Record<string, unknown>) => void;
    handleOrderChange: (order: string, column: string) => void;
    handleLocationFilterChange: (location: string) => void;
    handleActivityFilterChange: (lastActive: string) => void;
    handleRowDelete: (id: number) => void;
    handleRowRestore: (id: number) => void;
    handleStartDateChange: (date: string) => void;
    handleEndDateChange: (date: string) => void;
    check: (value: CT[]) => void;
    uncheck: (value: CT[]) => void;
    toggleCheck: (value: CT) => void;
}

type useListProps<T> = PropsWithChildren<{
    pagination?: boolean;
    plural: string;
    getData: (args: Record<string, unknown> & ListParameters) => Promise<{ items: T[]; total: number }>;
    includes?: Record<string, unknown>;
    filters?: Record<string, unknown>;
}>;
const ListContext = React.createContext({} as ListContextI<unknown, unknown>);

export function ListProvider<T extends { id: number; [key: string]: unknown }, CT = unknown>(
    props: useListProps<T>,
): JSX.Element {
    const Context = ListContext as unknown as Context<ListContextI<T, CT>>;
    const { getData, plural, includes, filters, children, pagination = true } = props;

    const [list, setList] = useState<T[]>([]);
    const [total, setTotal] = useState<number | undefined>();
    const [fetchParams, setFetchParams] = useState<{
        currentPage: number;
        searchTerm?: string;
        filter?: Record<string, unknown>;
        orderDirection?: string;
        selectedColumn?: string;
        locationFilter?: string;
        activityFilter?: string;
        idToDelete?: number;
        idToRestore?: number;
        startDate?: string;
        endDate?: string;
    }>({ currentPage: 0 });
    const [loading, setLoading] = useState<boolean>(false);
    const [initialised, setInitialised] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [checked, setChecked] = useState<Array<CT>>([]);
    const { model } = useCrud<T>();
    let columnTitles: string[] = [];
    let columnLabels: string[] = [];
    if (model) {
        columnTitles = Object.entries(model)
            .filter(([, { inTable = true, inExport = false }]) => inTable || inExport)
            .map(([property]) => property);

        columnLabels = Object.entries(model)
            .filter(([, { inTable = true, inExport = false }]) => inTable || inExport)
            .map(([, { label }]) => label);
    }

    const fetchList = useCallback(
        (args: Record<string, unknown> & ListParameters = {}) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);

                getData({
                    ...filters,
                    ...args,
                    ...includes,
                    ...fetchParams.filter,
                    ...(fetchParams.orderDirection && { orderDirection: fetchParams.orderDirection }),
                    ...(fetchParams.selectedColumn && { selectedColumn: fetchParams.selectedColumn }),
                    ...(fetchParams.locationFilter && { locationFilter: fetchParams.locationFilter }),
                    ...(fetchParams.activityFilter && { activityFilter: fetchParams.activityFilter }),
                    ...(fetchParams.idToDelete && { idToDelete: fetchParams.idToDelete }),
                    ...(fetchParams.idToRestore && { idToRestore: fetchParams.idToRestore }),
                    ...(fetchParams.startDate && { startDate: fetchParams.startDate }),
                    ...(fetchParams.endDate && { endDate: fetchParams.endDate }),
                    ...(fetchParams.searchTerm && { searchTerm: fetchParams.searchTerm }),
                    ...(pagination && { skip: fetchParams.currentPage * 10, take: 10 }),
                })
                    .then(async (response: { items: T[]; total: number }) => {
                        const { items } = response;
                        setList(items);
                        setTotal(items.length);
                        setError(undefined);
                        resolve();
                    })
                    .catch(() => {
                        setList([]);
                        setTotal(undefined);
                        setError(`Unable to fetch ${plural}`);
                        reject(`Unable to fetch ${plural}`);
                    })
                    .finally(() => {
                        setLoading(false);
                        setInitialised(true);
                    });
            });
        },
        [
            includes,
            filters,
            pagination,
            plural,
            fetchParams.currentPage,
            fetchParams.searchTerm,
            fetchParams.filter,
            fetchParams.orderDirection,
            fetchParams.selectedColumn,
            fetchParams.locationFilter,
            fetchParams.activityFilter,
            fetchParams.idToDelete,
            fetchParams.idToRestore,
            fetchParams.startDate,
            fetchParams.endDate,
        ],
    );

    const fetchExportList = useCallback(
        (args: Record<string, unknown> & ListParameters = {}) => {
            return new Promise<T[]>((resolve, reject) => {
                getData({
                    ...filters,
                    ...args,
                    ...includes,
                    ...fetchParams.filter,
                    ...(fetchParams.searchTerm && { searchTerm: fetchParams.searchTerm }),
                    ...(fetchParams.orderDirection && { orderDirection: fetchParams.orderDirection }),
                    ...(fetchParams.selectedColumn && { selectedColumn: fetchParams.selectedColumn }),
                    ...(fetchParams.locationFilter && { locationFilter: fetchParams.locationFilter }),
                    ...(fetchParams.activityFilter && { activityFilter: fetchParams.activityFilter }),
                    ...(fetchParams.startDate && { startDate: fetchParams.startDate }),
                    ...(fetchParams.endDate && { endDate: fetchParams.endDate }),
                    ...(fetchParams.idToDelete && { idToDelete: fetchParams.idToDelete }),
                    ...(fetchParams.idToRestore && { idToRestore: fetchParams.idToRestore }),
                    ...{ skip: 0, take: 1000000000 },
                })
                    .then(async (response: { items: T[]; total: number }) => {
                        const { items } = response;
                        resolve(items);
                    })
                    .catch(() => {
                        setError(`Unable to fetch ${plural} export`);
                        reject(`Unable to fetch ${plural} export`);
                    });
            });
        },
        [
            includes,
            filters,
            plural,
            fetchParams.searchTerm,
            fetchParams.filter,
            fetchParams.orderDirection,
            fetchParams.selectedColumn,
            fetchParams.locationFilter,
            fetchParams.activityFilter,
            fetchParams.idToDelete,
            fetchParams.idToRestore,
            fetchParams.startDate,
            fetchParams.endDate,
        ],
    );

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    const handlePageChange = useCallback((page: number) => {
        setFetchParams((currentParams) => ({ ...currentParams, currentPage: page }));
    }, []);

    const handleSearchTermChange = useCallback((searchTerm: string) => {
        setFetchParams((currentParams) => ({ ...currentParams, currentPage: 0, searchTerm }));
    }, []);

    const handleFilterChange = useCallback((filter: Record<string, unknown>) => {
        setFetchParams((currentParams) => ({ ...currentParams, filter, currentPage: 0 }));
    }, []);

    const handleOrderChange = useCallback((order: string, column: string) => {
        if (order === "Ascending") {
            setFetchParams((currentParams) => ({ ...currentParams, orderDirection: "asc", currentPage: 0 }));
        } else {
            setFetchParams((currentParams) => ({ ...currentParams, orderDirection: "desc", currentPage: 0 }));
        }

        const columnIndex = columnLabels.indexOf(column);
        const correspondingTitle = columnTitles[columnIndex];

        if (correspondingTitle === "name") {
            setFetchParams((currentParams) => ({ ...currentParams, selectedColumn: "firstName", currentPage: 0 }));
        } else {
            setFetchParams((currentParams) => ({
                ...currentParams,
                selectedColumn: correspondingTitle,
                currentPage: 0,
            }));
        }
    }, []);

    const handleLocationFilterChange = useCallback((location: string) => {
        setFetchParams((currentParams) => ({ ...currentParams, locationFilter: location, currentPage: 0 }));
    }, []);

    const handleActivityFilterChange = useCallback((lastActive: string) => {
        setFetchParams((currentParams) => ({ ...currentParams, activityFilter: lastActive, currentPage: 0 }));
    }, []);

    const handleRowDelete = useCallback((id: number) => {
        setFetchParams((currentParams) => ({ ...currentParams, idToDelete: id, currentPage: 0 }));
    }, []);

    const handleRowRestore = useCallback((id: number) => {
        setFetchParams((currentParams) => ({ ...currentParams, idToRestore: id, currentPage: 0 }));
    }, []);

    const handleStartDateChange = useCallback((date: string) => {
        setFetchParams((currentParams) => ({ ...currentParams, startDate: date, currentPage: 0 }));
    }, []);

    const handleEndDateChange = useCallback((date: string) => {
        setFetchParams((currentParams) => ({ ...currentParams, endDate: date, currentPage: 0 }));
    }, []);

    const check = useCallback((checkValues: CT[]) => setChecked((checked) => [...checked, ...checkValues]), []);
    const uncheck = useCallback(
        (uncheckValues: CT[]) =>
            setChecked((checked) => checked.filter((value) => uncheckValues.indexOf(value) === -1)),
        [],
    );
    const toggleCheck = useCallback(
        (toggleValue: CT) =>
            setChecked((checked) =>
                checked.indexOf(toggleValue) === -1
                    ? [...checked, toggleValue]
                    : checked.filter((value) => value !== toggleValue),
            ),
        [],
    );

    return (
        <Context.Provider
            value={{
                list,
                total,
                currentPage: fetchParams.currentPage,
                loading,
                error,
                fetchList,
                fetchExportList,
                handlePageChange,
                handleSearchTermChange,
                handleFilterChange,
                handleOrderChange,
                handleLocationFilterChange,
                handleActivityFilterChange,
                handleRowDelete,
                handleRowRestore,
                handleStartDateChange,
                handleEndDateChange,
                initialised,
                checked,
                check,
                uncheck,
                toggleCheck,
                plural,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useList<T, CT = unknown>(): ListContextI<T, CT> {
    return React.useContext(ListContext as unknown as Context<ListContextI<T, CT>>);
}
