import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useCurriculum } from "../../../../hooks/useCurriculum";
import { Curriculum } from "common/build/prisma/client";
import { useProgramme } from "../../contexts/programme.context";
import { PathwayCard } from "./PathwayCard";
import { CurriculumType, StoryCollectionType } from "../../../../models/modelTypes";
import { WizardHeading } from "../../../../core/components/WizardHeading";
import { Search } from "@material-ui/icons";
// import { useAuth } from "../../../../contexts/auth.context";
// import { generateAgeRanges } from "common/build/utils/storyCollectionAgeRanges";

const PathwayStep = (): JSX.Element => {
    // const { user } = useAuth();
    const { listAll, curriculums } = useCurriculum();
    const { submitStep, changeStep } = useProgramme();

    const [results, setResults] = useState<StoryCollectionType[]>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [ageRange, setAgeRange] = useState<string>("");
    const [curriculum, setCurriculum] = useState<CurriculumType | undefined>(undefined);
    const [availAgeRanges, setAvailAgeRanges] = useState<string[]>([]);
    const [availCurriculums, setAvailCurriculums] = useState<CurriculumType[]>([]);

    // gets all curriculums
    useEffect(() => {
        listAll();
    }, []);

    // Set available curriculums to curriculums with collections
    useMemo(() => {
        if (curriculums && curriculums?.length > 0) {
            const cs: CurriculumType[] = [];
            // Set curriculums based on ageFilter results
            if (ageRange !== "") {
                curriculums.forEach((c) => {
                    if (c.StoryCollection?.length > 0) {
                        c.StoryCollection.forEach((sc) => {
                            if (sc.ageRange === ageRange) {
                                cs.push(c);
                            }
                        });
                    }
                });
            } else {
                curriculums.forEach((c) => {
                    if (c.StoryCollection?.length > 0) {
                        cs.push(c);
                    }
                });
            }

            setAvailCurriculums(cs);
        }
    }, [curriculums, ageRange]);

    // Update available age ranges when available curriculums changes or if a curriculum is selected
    useMemo(() => {
        if (curriculum) {
            const ages: string[] = [];
            curriculum.StoryCollection.forEach((sc) => {
                if (!ages.includes(sc.ageRange)) {
                    ages.push(sc.ageRange);
                }
            });

            setAvailAgeRanges(ages);
        } else {
            if (availCurriculums.length > 0) {
                const ages: string[] = [];
                availCurriculums.forEach((c) => {
                    if (c.StoryCollection?.length > 0) {
                        c.StoryCollection.forEach((sc) => {
                            if (!ages.includes(sc.ageRange)) {
                                ages.push(sc.ageRange);
                            }
                        });
                    }
                });

                setAvailAgeRanges(ages);
            }
        }
    }, [curriculum, availCurriculums]);

    const handleChangeCurriculum = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = parseInt(event.target.value as string);
        const curr = availCurriculums.find((c) => c.id == value);
        setCurriculum(curr);
    };

    const handleChangeAge = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = event.target.value as string;
        setAgeRange(value);
    };

    const updateFilter = useCallback(() => {
        const tempResults: StoryCollectionType[] = [];

        if (curriculum) {
            if (ageRange !== "") {
                if (curriculum.StoryCollection.length > 0) {
                    curriculum.StoryCollection.forEach((sc) => {
                        if (sc.ageRange === ageRange) {
                            tempResults.push(sc);
                        }
                    });
                }
            } else {
                if (curriculum.StoryCollection.length > 0) {
                    curriculum.StoryCollection.forEach((sc) => tempResults.push(sc));
                }
            }
        } else if (ageRange !== "") {
            availCurriculums.forEach((c) => {
                if (c.StoryCollection.length > 0) {
                    c.StoryCollection.forEach((sc) => {
                        if (sc.ageRange === ageRange) {
                            tempResults.push(sc);
                        }
                    });
                }
            });
        } else {
            if (availCurriculums.length > 0) {
                availCurriculums.forEach((c) => tempResults.push(...c.StoryCollection));
            }
        }
        setResults(tempResults);
    }, [ageRange, curriculum, availCurriculums]);

    useEffect(() => {
        updateFilter();
    }, [ageRange, curriculum, availCurriculums]);

    const resetAllCurriculums = () => {
        if (availCurriculums.length > 0) {
            const tempResults: StoryCollectionType[] = [];
            availCurriculums.forEach((curriculum) => {
                tempResults.push(...curriculum.StoryCollection);
            });
            setResults(tempResults);
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };

    useEffect(() => {
        const tempResults: StoryCollectionType[] = [];
        if (searchString && searchString.length > 0) {
            curriculums?.forEach((curriculum) => {
                curriculum.StoryCollection?.forEach((pathway) => {
                    if (pathway.name?.toLowerCase().includes(searchString.toLowerCase())) {
                        tempResults.push(pathway);
                    }
                });
            });
            setResults(tempResults);
        } else {
            resetAllCurriculums();
        }
    }, [searchString]);

    // const ageRanges = generateAgeRanges();

    const savePathway = (storyCollectionId: number, ageRange: string) => {
        submitStep({ storyCollectionId: storyCollectionId, ageRange: ageRange });
        changeStep(1);
    };

    // if (!user?.isSchool) ageRanges.push("18+");

    return (
        <>
            <WizardHeading overline={"Schedule a programme"} heading={"Select Collection"} />
            <Grid container spacing={3} justify={"space-between"} className="selectCollectionList">
                <Grid item xs={6} sm={4} className="storyCollectionFilter1">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="curriculums">Filter by Curriculum</InputLabel>
                        <Select
                            fullWidth
                            labelId="curriculums"
                            label="Filter by Curriculum"
                            name="curriculums"
                            onChange={handleChangeCurriculum}
                            variant={"outlined"}
                        >
                            <MenuItem value={undefined}>All Curriculums</MenuItem>
                            {availCurriculums?.map((curriculum: Curriculum) => (
                                <MenuItem key={curriculum.id} value={curriculum.id}>
                                    {curriculum.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} className="storyCollectionFilter2">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="ages">Filter by Age Range</InputLabel>
                        <Select
                            fullWidth
                            labelId="ages"
                            label="Filter by Age Range"
                            name="ages"
                            onChange={handleChangeAge}
                            variant={"outlined"}
                        >
                            <MenuItem key={0} value={""}>
                                Please select
                            </MenuItem>
                            {availAgeRanges.map((ageRange) => (
                                <MenuItem key={ageRange} value={ageRange}>
                                    {ageRange}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={6} className="storyCollectionFilter3">
                    <TextField
                        autoFocus
                        id="standard-search"
                        type="search"
                        name="searchString"
                        placeholder="Search"
                        onChange={handleSearch}
                        fullWidth
                        value={searchString}
                        variant={"outlined"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={"start"}>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {results?.map((storyCollection: StoryCollectionType) => (
                    <PathwayCard key={storyCollection.id} storyCollection={storyCollection} save={savePathway} />
                ))}
            </Grid>
        </>
    );
};

export default PathwayStep;
